@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");

.App {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.mainImage {
  height: 40vmin;
  pointer-events: none;
  border-radius: 50%;
  overflow: hidden;
  width: 9em;
  height: 9em;
  background-color: #f1f1f1;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

* {
  font-family: "Fredoka One", cursive;
}

.mainImageWrapper {
  border: 1px solid #dc99b5;
  width: 10em;
  height: 10em;
  border-radius: 100%;
  content: "";
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0.8em;
}

.mainImage img {
  width: 100%;
  height: 100%;
}

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #212121;
  height: 95vh;
}

.App-link {
  color: #61dafb;
}

.mainTitle {
  font-size: 2.6em;
}

.mainTitle b {
  color: #d43879;
}

.description {
  margin: 1.4em 2em;
  font-family: "Baloo 2", cursive;
  line-height: 1.4em;
  font-size: 1em;
}

.links {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin-top: 2em;
}

.links a {
  color: #212121;
  text-decoration: none;
  cursor: pointer;
}

.links a:hover {
  color: #d43879;
}

.flex {
  display: flex;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 600px) {
  .App-header {
    margin: 1em 0;
    height: auto;
  }
}
